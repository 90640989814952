import type { NextPage } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import Image from "next/image";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useAuth } from "../context/Authentication";
import { BoxColumn } from "../components/common/Boxes";
import { PageHead } from "../components/PageHead";
import { useSpring, animated, config } from "react-spring";

const Home: NextPage = () => {
  return (
    <>
      <PageHead />

      <Content />
    </>
  );
};

const Content = () => {
  const { user, errorMessage, signIn, chainId } = useAuth();
  const router = useRouter();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <BoxColumn
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center"
      }}
    >
      {errorMessage && (
        <Typography variant="h6" sx={{ color: "red" }}>
          {errorMessage}
        </Typography>
      )}

      <BoxColumn
        sx={{
          width: "100%",
          height: "100%",
          //   justifyContent: "center",
          alignItems: "center",
          gap: 1,
          paddingTop: 2
        }}
      >
        {/* <iframe
          width={isMobile ? "100%" : "800"}
          height={isMobile ? "300" : "500"}
          src="https://www.youtube.com/embed/Un7vRXY-Iig?si=D4JhmtJLnV0SfhR2"
          title="YouTube video player"
          frameBorder={0}
          // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        /> */}
        <Box
          sx={{
            mt: 5,
            mb: 2,
            boxShadow: "11px 12px 23px 0px rgba(0,0,0,0.61)"
          }}
        >
          <Image
            src="/cards/runes_cardgame_card_alchemiststifly_betatesterreward.gif"
            alt="homepage image"
            width="250"
            height="333"
          />
        </Box>
        <BoxColumn
          sx={{
            p: 1,
            width: 600,
            maxWidth: "100%",
            textAlign: "center",
            borderRadius: 1,
            mb: 2,
            gap: 2
          }}
        >
          <Typography variant="h5">
            Runes is a trading card game set in the Runiverse, the world being
            realized by the Forgotten Runes Wizard Cult team & community.
          </Typography>
          <SpookyText />
          {/* <Typography variant="h5" color="orange">
            Now featuring the Souls expansion!
          </Typography> */}
        </BoxColumn>
        {!user && (
          <BoxColumn sx={{ gap: 1, paddingBottom: 2 }}>
            {/* <Typography style={{ color: "#ccc" }}>
              Sign in to unlock in-game functionality
            </Typography> */}
            <SignInButton chainId={chainId} signIn={signIn} />
            {/* <Button
              disabled={!chainId}
              onClick={signIn}
              sx={{ gap: 2, width: "fit-content", px: 2 }}
            >
              <span>Sign In With Metamask</span>
              <Image
                alt="metamask"
                src="/metamask-fox.svg"
                width="50px"
                height="50px"
              />
            </Button> */}
          </BoxColumn>
        )}
      </BoxColumn>
    </BoxColumn>
  );
};

export default Home;

const AnimatedButton = animated(Button);
const AnimatedImage = animated(Image);

const SignInButton = ({
  chainId,
  signIn
}: {
  chainId: number | undefined;
  signIn: () => void;
}) => {
  const initialColor = "black";
  const secondColor = "orange";

  const [buttonProps, setButtonProps] = useSpring(() => ({
    transform: "scale(1)",
    backgroundColor: initialColor, // Initial background color
    config: {
      tension: 400,
      friction: 22,
      clamp: true
    }
  }));

  const [imageProps, setImageProps] = useSpring(() => ({
    transform: "rotate(0deg)",
    config: { tension: 400, friction: 20 }
  }));

  return (
    <AnimatedButton
      onMouseEnter={() => {
        setButtonProps({
          transform: "scale(1.05)", // Slightly enlarge on hover
          backgroundColor: secondColor // Change to a different color on hover
        });
        setImageProps({ transform: "rotate(180deg)" }); // Rotate the Metamask icon
      }}
      onMouseLeave={() => {
        setButtonProps({
          transform: "scale(1)", // Return to normal size
          backgroundColor: initialColor // Return to initial color
        });
        setImageProps({ transform: "rotate(0deg)" }); // Rotate back the Metamask icon
      }}
      style={buttonProps}
      disabled={!chainId}
      onClick={signIn}
      sx={{
        gap: 2,
        width: "fit-content",
        px: 4,
        py: 1,
        borderRadius: 4,
        border: "1px solid white"
      }}
    >
      <span style={{ color: "white" }}>Sign In With Metamask</span>
      <AnimatedImage
        alt="metamask"
        src="/metamask-fox.svg"
        width="50"
        height="50"
        style={imageProps}
      />
    </AnimatedButton>
  );
};

// const SignInButton = ({
//   chainId,
//   signIn
// }: {
//   chainId: number | undefined;
//   signIn: () => void;
// }) => {
//   const initialColor = "black";
//   const secondColor = "orange";
//   const [props, set] = useSpring(() => ({
//     transform: "scale(1)",
//     backgroundColor: initialColor // Initial background color
//   }));

//   return (
//     <AnimatedButton
//       onMouseEnter={() =>
//         set({
//           transform: "scale(1.05)", // Slightly enlarge on hover
//           // backgroundColor: "#EE8000" // Change to a different color on hover
//           backgroundColor: secondColor // Change to a different color on hover
//         })
//       }
//       onMouseLeave={() =>
//         set({
//           transform: "scale(1)", // Return to normal size
//           // backgroundColor: "#FFA500" // Return to initial color
//           backgroundColor: initialColor
//         })
//       }
//       style={props}
//       disabled={!chainId}
//       onClick={signIn}
//       sx={{ gap: 2, width: "fit-content", px: 4, py: 1, borderRadius: 4 }}
//     >
//       <span style={{ color: "white" }}>Sign In With Metamask</span>
//       <Image
//         alt="metamask"
//         src="/metamask-fox.svg"
//         width="50px"
//         height="50px"
//       />
//     </AnimatedButton>
//   );
// };

const AnimatedTypography = animated(Typography);

const SpookyText = () => {
  const [props, set] = useSpring(() => ({
    textShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    transform: "scale(1)",
    skewX: "0deg",
    skewY: "0deg",
    config: config.wobbly
  }));

  return (
    <AnimatedTypography
      onMouseEnter={() => {
        set({
          textShadow: "2px 2px 12px rgba(255, 105, 0, 0.8)",
          transform: "scale(1.05)",
          skewX: "-5deg",
          skewY: "-3deg"
        });
      }}
      onMouseLeave={() => {
        set({
          textShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          transform: "scale(1)",
          skewX: "0deg",
          skewY: "0deg"
        });
      }}
      variant="h5"
      color="orange"
      style={props}
    >
      Now featuring the Souls expansion!
    </AnimatedTypography>
  );
};
