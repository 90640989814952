import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type Deck = {
  __typename?: 'Deck';
  chosenCardTokenIds: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  userId: Scalars['Int'];
};

export type EntrantWithRecordApi = {
  __typename?: 'EntrantWithRecordApi';
  ensName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isCurrentlyPaired?: Maybe<Scalars['Boolean']>;
  losses?: Maybe<Scalars['Int']>;
  matchesPlayed?: Maybe<Scalars['Int']>;
  publicAddress?: Maybe<Scalars['String']>;
  winner?: Maybe<Scalars['Boolean']>;
  wins?: Maybe<Scalars['Int']>;
};

export type Game = {
  __typename?: 'Game';
  currentGame: Scalars['Boolean'];
  firstMove: Scalars['Int'];
  id: Scalars['ID'];
  isStarted: Scalars['Boolean'];
  isTie?: Maybe<Scalars['Boolean']>;
  lobbyPlayerChosenCards: Array<LobbyPlayerChosenCards>;
  loser?: Maybe<Scalars['Int']>;
  matchId: Scalars['String'];
  numberInMatch: Scalars['Int'];
  playedCards: Array<PlayedCard>;
  winner?: Maybe<Scalars['Int']>;
};

export type GetAllTournamentsQueryResponse = {
  __typename?: 'GetAllTournamentsQueryResponse';
  activeTournaments: Array<Maybe<Tournament>>;
  closedTournaments: Array<Maybe<Tournament>>;
  openTournaments: Array<Maybe<Tournament>>;
};

export type JwtResponse = {
  __typename?: 'JWTResponse';
  jwt?: Maybe<Scalars['String']>;
};

export type Lobby = {
  __typename?: 'Lobby';
  activeUsers: Array<User>;
  currentGame: Game;
  firstMovePlayerId?: Maybe<Scalars['Int']>;
  gameNumber?: Maybe<Scalars['Int']>;
  havePlayersSelectedCards?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isOpen: Scalars['Boolean'];
  isStarted?: Maybe<Scalars['Boolean']>;
  matchScore: Scalars['JSON'];
  name: Scalars['String'];
  playedCards: Array<Maybe<PlayedCard>>;
  playerChosenCardsMetadata?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  playerChosenDeck?: Maybe<Deck>;
  players: Array<User>;
  settings?: Maybe<LobbySettings>;
  user: User;
  userId: Scalars['Int'];
};

export type LobbyPlayerChosenCards = {
  __typename?: 'LobbyPlayerChosenCards';
  chosenCardTokenIds: Array<Scalars['String']>;
  chosenDeckId?: Maybe<Scalars['String']>;
  gameId: Scalars['String'];
  id: Scalars['Int'];
  userId: Scalars['Int'];
};

export type LobbySettings = {
  __typename?: 'LobbySettings';
  backgroundTokenId?: Maybe<Scalars['Int']>;
  board: Scalars['String'];
  numberOfWins: Scalars['Int'];
  potencyCap?: Maybe<Scalars['Int']>;
  useTimer: Scalars['Boolean'];
};

export type LobbyTournamentMatchJoin = {
  __typename?: 'LobbyTournamentMatchJoin';
  id: Scalars['Int'];
  lobby: Lobby;
  lobbyId: Scalars['String'];
  tournamentMatch: TournamentMatch;
  tournamentMatchId: Scalars['Int'];
};

export type Match = {
  __typename?: 'Match';
  createdAt: Scalars['DateTime'];
  currentMatch: Scalars['Boolean'];
  games: Array<Game>;
  id: Scalars['ID'];
  lobbyId: Scalars['String'];
  loser?: Maybe<Scalars['Int']>;
  winner?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  TESTcompleteTournamentMatches?: Maybe<Scalars['Boolean']>;
  TESTgenerateOngoingMatches?: Maybe<Scalars['Boolean']>;
  TESTmoveStartTimeToNow?: Maybe<Scalars['Boolean']>;
  TESTregisterEntrants?: Maybe<Array<TournamentEntrant>>;
  chooseLobbyCardsAndDeck?: Maybe<Scalars['JSON']>;
  closeLobby?: Maybe<Lobby>;
  createDeck?: Maybe<Deck>;
  createLobby?: Maybe<Lobby>;
  createSwiss1v1Tournament?: Maybe<Tournament>;
  createTournamentMatch?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<User>;
  deleteDeck?: Maybe<Deck>;
  deleteLobby?: Maybe<Lobby>;
  deleteTournamentMatch?: Maybe<TournamentMatch>;
  deleteUser?: Maybe<User>;
  enterTournament?: Maybe<Scalars['Boolean']>;
  exitLobby?: Maybe<User>;
  joinLobby?: Maybe<User>;
  joinQuickmatchQueue?: Maybe<Lobby>;
  leaveQuickmatchQueue?: Maybe<Scalars['Boolean']>;
  nextGameInMatch?: Maybe<Lobby>;
  playCard?: Maybe<PlayedCard>;
  resetGame?: Maybe<Lobby>;
  selectLobbyOpponent?: Maybe<Lobby>;
  signInUser?: Maybe<JwtResponse>;
  startGame?: Maybe<Lobby>;
  startTournament?: Maybe<Tournament>;
  stopGame?: Maybe<Lobby>;
  updateDeck?: Maybe<Deck>;
};


export type MutationTesTcompleteTournamentMatchesArgs = {
  tournamentId: Scalars['String'];
  tournamentMatchIds: Array<Scalars['Int']>;
};


export type MutationTesTgenerateOngoingMatchesArgs = {
  tournamentId: Scalars['String'];
};


export type MutationTesTmoveStartTimeToNowArgs = {
  tournamentId: Scalars['String'];
};


export type MutationTesTregisterEntrantsArgs = {
  numberOfPlayers: Scalars['Int'];
  tournamentId: Scalars['String'];
};


export type MutationChooseLobbyCardsAndDeckArgs = {
  cardTokenIds: Array<Scalars['String']>;
  deckId?: InputMaybe<Scalars['String']>;
  lobbyId: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCloseLobbyArgs = {
  lobbyId: Scalars['String'];
};


export type MutationCreateDeckArgs = {
  chosenCardTokenIds: Array<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreateLobbyArgs = {
  backgroundTokenId?: InputMaybe<Scalars['Int']>;
  board?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  numberOfWins?: InputMaybe<Scalars['Int']>;
  potencyCap?: InputMaybe<Scalars['Int']>;
  tournamentId?: InputMaybe<Scalars['String']>;
  useTimer: Scalars['Boolean'];
};


export type MutationCreateSwiss1v1TournamentArgs = {
  board?: InputMaybe<Scalars['String']>;
  endTime: Scalars['String'];
  name: Scalars['String'];
  potency?: InputMaybe<Scalars['Int']>;
  requiredWins?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['String'];
  timerRequired?: InputMaybe<Scalars['Boolean']>;
  type: Scalars['String'];
};


export type MutationCreateTournamentMatchArgs = {
  entrant1: Scalars['Int'];
  entrant2?: InputMaybe<Scalars['Int']>;
  tournamentId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  publicAddress: Scalars['String'];
};


export type MutationDeleteDeckArgs = {
  deckId: Scalars['String'];
};


export type MutationDeleteLobbyArgs = {
  lobbyId: Scalars['String'];
};


export type MutationDeleteTournamentMatchArgs = {
  tournamentMatchId: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  publicAddress: Scalars['String'];
};


export type MutationEnterTournamentArgs = {
  discordHandle?: InputMaybe<Scalars['String']>;
  tournamentId: Scalars['String'];
};


export type MutationExitLobbyArgs = {
  lobbyId: Scalars['String'];
};


export type MutationJoinLobbyArgs = {
  lobbyId: Scalars['String'];
};


export type MutationNextGameInMatchArgs = {
  lobbyId: Scalars['String'];
};


export type MutationPlayCardArgs = {
  boardPositionCol: Scalars['Int'];
  boardPositionRow: Scalars['Int'];
  lobbyId: Scalars['String'];
  tokenId: Scalars['String'];
};


export type MutationResetGameArgs = {
  lobbyId: Scalars['String'];
};


export type MutationSelectLobbyOpponentArgs = {
  lobbyId: Scalars['String'];
  opponentId: Scalars['Int'];
};


export type MutationSignInUserArgs = {
  publicAddress: Scalars['String'];
  signature: Scalars['String'];
};


export type MutationStartGameArgs = {
  lobbyId: Scalars['String'];
};


export type MutationStartTournamentArgs = {
  tournamentId: Scalars['String'];
};


export type MutationStopGameArgs = {
  lobbyId: Scalars['String'];
};


export type MutationUpdateDeckArgs = {
  chosenCardTokenIds: Array<Scalars['String']>;
  deckId: Scalars['String'];
  name: Scalars['String'];
};

export type PlayedCard = {
  __typename?: 'PlayedCard';
  boardPositionCol: Scalars['Int'];
  boardPositionRow: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  game: Game;
  gameId: Scalars['String'];
  lobbyId?: Maybe<Scalars['String']>;
  playedCardMetadata?: Maybe<Scalars['JSON']>;
  tokenId: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
};

/** PlayerStats */
export type PlayerStats = {
  __typename?: 'PlayerStats';
  publicAddress?: Maybe<Scalars['String']>;
  totalLosses?: Maybe<Scalars['Int']>;
  totalWins?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  backgrounds?: Maybe<Array<Scalars['JSON']>>;
  burnStatus: Scalars['JSON'];
  cardPacks?: Maybe<Array<Scalars['JSON']>>;
  cards?: Maybe<Array<Scalars['JSON']>>;
  deck?: Maybe<Deck>;
  decks: Array<Deck>;
  getAllTournaments?: Maybe<Array<Tournament>>;
  getAllTournamentsCategorized: GetAllTournamentsQueryResponse;
  getTournament: Tournament;
  getUserTournamentsForLobbyCreation?: Maybe<Array<Tournament>>;
  isUserAdmin?: Maybe<Scalars['Boolean']>;
  lobbies?: Maybe<Array<Lobby>>;
  lobby?: Maybe<Lobby>;
  lobbyTimer?: Maybe<Scalars['JSON']>;
  playerChosenCards?: Maybe<LobbyPlayerChosenCards>;
  playerRecords?: Maybe<Array<PlayerStats>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryBackgroundsArgs = {
  publicAddress: Scalars['String'];
};


export type QueryBurnStatusArgs = {
  transactionHash: Scalars['String'];
};


export type QueryCardPacksArgs = {
  publicAddress: Scalars['String'];
};


export type QueryCardsArgs = {
  publicAddress: Scalars['String'];
};


export type QueryDeckArgs = {
  id: Scalars['String'];
};


export type QueryGetAllTournamentsArgs = {
  openForEntry?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetTournamentArgs = {
  name: Scalars['String'];
};


export type QueryLobbyArgs = {
  name: Scalars['String'];
};


export type QueryLobbyTimerArgs = {
  lobbyId: Scalars['String'];
};


export type QueryPlayerChosenCardsArgs = {
  lobbyId: Scalars['String'];
  userId: Scalars['Int'];
};


export type QueryUserArgs = {
  publicAddress: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  playedCard?: Maybe<PlayedCard>;
  startGame?: Maybe<Lobby>;
};


export type SubscriptionPlayedCardArgs = {
  lobbyId: Scalars['String'];
};


export type SubscriptionStartGameArgs = {
  lobbyId: Scalars['String'];
};

export type SwissTournamentSettings = {
  __typename?: 'SwissTournamentSettings';
  board?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  potency?: Maybe<Scalars['Int']>;
  requiredWins?: Maybe<Scalars['Int']>;
  timerRequired?: Maybe<Scalars['Boolean']>;
  tournamentId: Scalars['String'];
};

export type Tournament = {
  __typename?: 'Tournament';
  endTime: Scalars['DateTime'];
  entrantsWithRecords: Array<Maybe<EntrantWithRecordApi>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  startTime: Scalars['DateTime'];
  tournamentEntrant: Array<TournamentEntrant>;
  tournamentMatch: Array<TournamentMatch>;
  tournamentSettings?: Maybe<SwissTournamentSettings>;
  type: Scalars['String'];
};

export type TournamentEntrant = {
  __typename?: 'TournamentEntrant';
  tournament: Tournament;
  tournamentEntrantId: Scalars['Int'];
  tournamentEntrantMatchPairing: Array<TournamentEntrantMatchPairing>;
  tournamentId: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
};

export type TournamentEntrantMatchPairing = {
  __typename?: 'TournamentEntrantMatchPairing';
  id: Scalars['Int'];
  tournamentEntrant: TournamentEntrant;
  tournamentEntrantId: Scalars['Int'];
  tournamentMatch: TournamentMatch;
  tournamentMatchId: Scalars['Int'];
};

export type TournamentMatch = {
  __typename?: 'TournamentMatch';
  lobbyTournamentMatchJoin?: Maybe<LobbyTournamentMatchJoin>;
  loser?: Maybe<Scalars['Int']>;
  loser_relation?: Maybe<TournamentEntrant>;
  pass?: Maybe<Scalars['Boolean']>;
  tie?: Maybe<Scalars['Boolean']>;
  tournament: Tournament;
  tournamentEntrantMatchPairing: Array<TournamentEntrantMatchPairing>;
  tournamentId: Scalars['String'];
  tournamentMatchId: Scalars['Int'];
  winner?: Maybe<Scalars['Int']>;
  winner_relation?: Maybe<TournamentEntrant>;
};

/** this is the user model, this message is shown in\nthe schema definition in apollo studio */
export type User = {
  __typename?: 'User';
  activeLobby?: Maybe<Lobby>;
  ensName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  joinedLobbyAt?: Maybe<Scalars['DateTime']>;
  nonce: Scalars['Int'];
  publicAddress: Scalars['String'];
};

export type CreateDeckMutationVariables = Exact<{
  chosenCardTokenIds: Array<Scalars['String']> | Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateDeckMutation = { __typename?: 'Mutation', createDeck?: { __typename?: 'Deck', name: string, id: string } | null };

export type GetDeckQueryVariables = Exact<{
  deckId: Scalars['String'];
}>;


export type GetDeckQuery = { __typename?: 'Query', deck?: { __typename?: 'Deck', chosenCardTokenIds: Array<string>, name: string } | null };

export type GetDecksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDecksQuery = { __typename?: 'Query', decks: Array<{ __typename?: 'Deck', chosenCardTokenIds: Array<string>, name: string, id: string }> };

export type UpdateDeckMutationVariables = Exact<{
  name: Scalars['String'];
  deckId: Scalars['String'];
  chosenCardTokenIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type UpdateDeckMutation = { __typename?: 'Mutation', updateDeck?: { __typename?: 'Deck', id: string } | null };

export type DeleteDeckMutationVariables = Exact<{
  deckId: Scalars['String'];
}>;


export type DeleteDeckMutation = { __typename?: 'Mutation', deleteDeck?: { __typename?: 'Deck', id: string } | null };

export type CreateLobbyMutationVariables = Exact<{
  name: Scalars['String'];
  numberOfWins?: InputMaybe<Scalars['Int']>;
  potencyCap?: InputMaybe<Scalars['Int']>;
  backgroundTokenId?: InputMaybe<Scalars['Int']>;
  useTimer: Scalars['Boolean'];
  tournamentId?: InputMaybe<Scalars['String']>;
  board?: InputMaybe<Scalars['String']>;
}>;


export type CreateLobbyMutation = { __typename?: 'Mutation', createLobby?: { __typename?: 'Lobby', id: string, name: string } | null };

export type LobbiesQueryVariables = Exact<{ [key: string]: never; }>;


export type LobbiesQuery = { __typename?: 'Query', lobbies?: Array<{ __typename?: 'Lobby', id: string, name: string, userId: number, isStarted?: boolean | null, firstMovePlayerId?: number | null, matchScore: any, gameNumber?: number | null, havePlayersSelectedCards?: any | null, user: { __typename?: 'User', publicAddress: string, id: number, nonce: number, ensName?: string | null }, settings?: { __typename?: 'LobbySettings', potencyCap?: number | null, numberOfWins: number, backgroundTokenId?: number | null, useTimer: boolean, board: string } | null, activeUsers: Array<{ __typename?: 'User', id: number, publicAddress: string, nonce: number, ensName?: string | null }>, playedCards: Array<{ __typename?: 'PlayedCard', userId: number, tokenId: string, lobbyId?: string | null, boardPositionCol: number, boardPositionRow: number, createdAt: any, playedCardMetadata?: any | null } | null>, players: Array<{ __typename?: 'User', id: number, publicAddress: string, ensName?: string | null }>, playerChosenDeck?: { __typename?: 'Deck', id: string, name: string, chosenCardTokenIds: Array<string> } | null }> | null };

export type LobbyQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type LobbyQuery = { __typename?: 'Query', lobby?: { __typename?: 'Lobby', id: string, name: string, isStarted?: boolean | null, matchScore: any, gameNumber?: number | null, firstMovePlayerId?: number | null, playerChosenCardsMetadata?: Array<any | null> | null, havePlayersSelectedCards?: any | null, activeUsers: Array<{ __typename?: 'User', id: number, publicAddress: string, ensName?: string | null }>, playedCards: Array<{ __typename?: 'PlayedCard', userId: number, tokenId: string, lobbyId?: string | null, boardPositionCol: number, boardPositionRow: number, createdAt: any, playedCardMetadata?: any | null } | null>, settings?: { __typename?: 'LobbySettings', potencyCap?: number | null, numberOfWins: number, backgroundTokenId?: number | null, useTimer: boolean, board: string } | null, players: Array<{ __typename?: 'User', id: number, publicAddress: string, ensName?: string | null }>, user: { __typename?: 'User', publicAddress: string, id: number, ensName?: string | null }, currentGame: { __typename?: 'Game', id: string }, playerChosenDeck?: { __typename?: 'Deck', id: string, name: string, chosenCardTokenIds: Array<string> } | null } | null };

export type JoinLobbyMutationVariables = Exact<{
  lobbyId: Scalars['String'];
}>;


export type JoinLobbyMutation = { __typename?: 'Mutation', joinLobby?: { __typename?: 'User', id: number } | null };

export type GameStartedSubscriptionVariables = Exact<{
  lobbyId: Scalars['String'];
}>;


export type GameStartedSubscription = { __typename?: 'Subscription', startGame?: { __typename?: 'Lobby', id: string, isStarted?: boolean | null } | null };

export type StartGameMutationVariables = Exact<{
  lobbyId: Scalars['String'];
}>;


export type StartGameMutation = { __typename?: 'Mutation', startGame?: { __typename?: 'Lobby', id: string } | null };

export type PlayedCardSubscriptionVariables = Exact<{
  lobbyId: Scalars['String'];
}>;


export type PlayedCardSubscription = { __typename?: 'Subscription', playedCard?: { __typename?: 'PlayedCard', userId: number, tokenId: string, lobbyId?: string | null, boardPositionCol: number, boardPositionRow: number, createdAt: any, playedCardMetadata?: any | null } | null };

export type PlayCardMutationVariables = Exact<{
  tokenId: Scalars['String'];
  lobbyId: Scalars['String'];
  boardPositionCol: Scalars['Int'];
  boardPositionRow: Scalars['Int'];
}>;


export type PlayCardMutation = { __typename?: 'Mutation', playCard?: { __typename?: 'PlayedCard', tokenId: string } | null };

export type ChooseLobbyCardsAndDeckMutationVariables = Exact<{
  lobbyId: Scalars['String'];
  userId: Scalars['Int'];
  cardTokenIds: Array<Scalars['String']> | Scalars['String'];
  deckId?: InputMaybe<Scalars['String']>;
}>;


export type ChooseLobbyCardsAndDeckMutation = { __typename?: 'Mutation', chooseLobbyCardsAndDeck?: any | null };

export type CloseLobbyMutationVariables = Exact<{
  lobbyId: Scalars['String'];
}>;


export type CloseLobbyMutation = { __typename?: 'Mutation', closeLobby?: { __typename?: 'Lobby', id: string } | null };

export type PlayerRecordsQueryVariables = Exact<{ [key: string]: never; }>;


export type PlayerRecordsQuery = { __typename?: 'Query', playerRecords?: Array<{ __typename?: 'PlayerStats', publicAddress?: string | null, totalWins?: number | null, totalLosses?: number | null }> | null };

export type PlayerChosenCardsQueryVariables = Exact<{
  lobbyId: Scalars['String'];
  userId: Scalars['Int'];
}>;


export type PlayerChosenCardsQuery = { __typename?: 'Query', playerChosenCards?: { __typename?: 'LobbyPlayerChosenCards', chosenCardTokenIds: Array<string> } | null };

export type SelectLobbyOpponentMutationVariables = Exact<{
  lobbyId: Scalars['String'];
  opponentId: Scalars['Int'];
}>;


export type SelectLobbyOpponentMutation = { __typename?: 'Mutation', selectLobbyOpponent?: { __typename?: 'Lobby', players: Array<{ __typename?: 'User', id: number, publicAddress: string }> } | null };

export type ResetGameMutationVariables = Exact<{
  lobbyId: Scalars['String'];
}>;


export type ResetGameMutation = { __typename?: 'Mutation', resetGame?: { __typename?: 'Lobby', id: string } | null };

export type CreateNextGameInMatchMutationVariables = Exact<{
  lobbyId: Scalars['String'];
}>;


export type CreateNextGameInMatchMutation = { __typename?: 'Mutation', nextGameInMatch?: { __typename?: 'Lobby', gameNumber?: number | null, matchScore: any, isStarted?: boolean | null } | null };

export type JoinQuickmatchQueueMutationVariables = Exact<{ [key: string]: never; }>;


export type JoinQuickmatchQueueMutation = { __typename?: 'Mutation', joinQuickmatchQueue?: { __typename?: 'Lobby', name: string } | null };

export type LeaveQuickmatchQueueMutationVariables = Exact<{ [key: string]: never; }>;


export type LeaveQuickmatchQueueMutation = { __typename?: 'Mutation', leaveQuickmatchQueue?: boolean | null };

export type GetLobbyTimerQueryVariables = Exact<{
  lobbyId: Scalars['String'];
}>;


export type GetLobbyTimerQuery = { __typename?: 'Query', lobbyTimer?: any | null };

export type CreateSwiss1v1TournamentMutationVariables = Exact<{
  name: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  type: Scalars['String'];
  requiredWins?: InputMaybe<Scalars['Int']>;
  potency?: InputMaybe<Scalars['Int']>;
  timerRequired?: InputMaybe<Scalars['Boolean']>;
  board?: InputMaybe<Scalars['String']>;
}>;


export type CreateSwiss1v1TournamentMutation = { __typename?: 'Mutation', createSwiss1v1Tournament?: { __typename?: 'Tournament', id: string, name: string, type: string, startTime: any, endTime: any, tournamentSettings?: { __typename?: 'SwissTournamentSettings', id: number, tournamentId: string, requiredWins?: number | null, potency?: number | null, timerRequired?: boolean | null, board?: string | null } | null } | null };

export type GetAllTournamentsQueryVariables = Exact<{
  openForEntry?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetAllTournamentsQuery = { __typename?: 'Query', getAllTournaments?: Array<{ __typename?: 'Tournament', id: string, name: string, type: string, startTime: any, endTime: any, tournamentSettings?: { __typename?: 'SwissTournamentSettings', tournamentId: string, requiredWins?: number | null, potency?: number | null, timerRequired?: boolean | null, board?: string | null } | null }> | null };

export type EnterTournamentMutationVariables = Exact<{
  tournamentId: Scalars['String'];
  discordHandle?: InputMaybe<Scalars['String']>;
}>;


export type EnterTournamentMutation = { __typename?: 'Mutation', enterTournament?: boolean | null };

export type StartTournamentMutationVariables = Exact<{
  tournamentId: Scalars['String'];
}>;


export type StartTournamentMutation = { __typename?: 'Mutation', startTournament?: { __typename?: 'Tournament', id: string, name: string, type: string, startTime: any, endTime: any, tournamentSettings?: { __typename?: 'SwissTournamentSettings', id: number, tournamentId: string, requiredWins?: number | null, potency?: number | null, timerRequired?: boolean | null, board?: string | null } | null, tournamentMatch: Array<{ __typename?: 'TournamentMatch', tournamentMatchId: number, tournamentEntrantMatchPairing: Array<{ __typename?: 'TournamentEntrantMatchPairing', tournamentEntrant: { __typename?: 'TournamentEntrant', user: { __typename?: 'User', publicAddress: string } } }> }> } | null };

export type GetAllTournamentsCategorizedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTournamentsCategorizedQuery = { __typename?: 'Query', getAllTournamentsCategorized: { __typename?: 'GetAllTournamentsQueryResponse', openTournaments: Array<{ __typename?: 'Tournament', id: string, name: string, type: string, startTime: any, endTime: any, tournamentSettings?: { __typename?: 'SwissTournamentSettings', id: number, tournamentId: string, requiredWins?: number | null, potency?: number | null, timerRequired?: boolean | null, board?: string | null } | null } | null>, closedTournaments: Array<{ __typename?: 'Tournament', id: string, name: string, type: string, startTime: any, endTime: any, tournamentSettings?: { __typename?: 'SwissTournamentSettings', id: number, tournamentId: string, requiredWins?: number | null, potency?: number | null, timerRequired?: boolean | null, board?: string | null } | null } | null>, activeTournaments: Array<{ __typename?: 'Tournament', id: string, name: string, type: string, startTime: any, endTime: any, tournamentSettings?: { __typename?: 'SwissTournamentSettings', id: number, tournamentId: string, requiredWins?: number | null, potency?: number | null, timerRequired?: boolean | null, board?: string | null } | null, entrantsWithRecords: Array<{ __typename?: 'EntrantWithRecordApi', id?: number | null, matchesPlayed?: number | null, wins?: number | null, losses?: number | null, isCurrentlyPaired?: boolean | null, ensName?: string | null, publicAddress?: string | null, winner?: boolean | null } | null> } | null> } };

export type GetTournamentQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetTournamentQuery = { __typename?: 'Query', getTournament: { __typename?: 'Tournament', id: string, name: string, type: string, startTime: any, endTime: any, tournamentSettings?: { __typename?: 'SwissTournamentSettings', potency?: number | null, requiredWins?: number | null, timerRequired?: boolean | null, board?: string | null } | null, tournamentMatch: Array<{ __typename?: 'TournamentMatch', tournamentMatchId: number, pass?: boolean | null, winner?: number | null, loser?: number | null, tie?: boolean | null, winner_relation?: { __typename?: 'TournamentEntrant', user: { __typename?: 'User', publicAddress: string, ensName?: string | null } } | null, loser_relation?: { __typename?: 'TournamentEntrant', user: { __typename?: 'User', publicAddress: string, ensName?: string | null } } | null, tournamentEntrantMatchPairing: Array<{ __typename?: 'TournamentEntrantMatchPairing', tournamentEntrant: { __typename?: 'TournamentEntrant', user: { __typename?: 'User', publicAddress: string, ensName?: string | null } } }>, lobbyTournamentMatchJoin?: { __typename?: 'LobbyTournamentMatchJoin', lobby: { __typename?: 'Lobby', name: string } } | null }>, entrantsWithRecords: Array<{ __typename?: 'EntrantWithRecordApi', id?: number | null, matchesPlayed?: number | null, wins?: number | null, losses?: number | null, isCurrentlyPaired?: boolean | null, ensName?: string | null, publicAddress?: string | null, winner?: boolean | null } | null> } };

export type GetUserTournamentsForLobbyCreationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserTournamentsForLobbyCreationQuery = { __typename?: 'Query', getUserTournamentsForLobbyCreation?: Array<{ __typename?: 'Tournament', id: string, name: string, type: string, startTime: any, endTime: any, tournamentSettings?: { __typename?: 'SwissTournamentSettings', tournamentId: string, requiredWins?: number | null, potency?: number | null, timerRequired?: boolean | null, board?: string | null } | null, tournamentMatch: Array<{ __typename?: 'TournamentMatch', tournamentEntrantMatchPairing: Array<{ __typename?: 'TournamentEntrantMatchPairing', tournamentEntrant: { __typename?: 'TournamentEntrant', user: { __typename?: 'User', ensName?: string | null, publicAddress: string } } }> }> }> | null };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', id: number, publicAddress: string, ensName?: string | null } | null> | null };

export type UserQueryVariables = Exact<{
  publicAddress: Scalars['String'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: number, publicAddress: string, nonce: number, joinedLobbyAt?: any | null, ensName?: string | null } | null };

export type SignInUserMutationVariables = Exact<{
  signature: Scalars['String'];
  publicAddress: Scalars['String'];
}>;


export type SignInUserMutation = { __typename?: 'Mutation', signInUser?: { __typename?: 'JWTResponse', jwt?: string | null } | null };

export type CreateUserMutationVariables = Exact<{
  publicAddress: Scalars['String'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', nonce: number } | null };

export type QueryUserCardsQueryVariables = Exact<{
  publicAddress: Scalars['String'];
}>;


export type QueryUserCardsQuery = { __typename?: 'Query', cards?: Array<any> | null };

export type QueryUserCardPacksQueryVariables = Exact<{
  publicAddress: Scalars['String'];
}>;


export type QueryUserCardPacksQuery = { __typename?: 'Query', cardPacks?: Array<any> | null };

export type QueryUserBackgroundsQueryVariables = Exact<{
  publicAddress: Scalars['String'];
}>;


export type QueryUserBackgroundsQuery = { __typename?: 'Query', backgrounds?: Array<any> | null };

export type QueryBurnStatusQueryVariables = Exact<{
  transactionHash: Scalars['String'];
}>;


export type QueryBurnStatusQuery = { __typename?: 'Query', burnStatus: any };

export type IsUserAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type IsUserAdminQuery = { __typename?: 'Query', isUserAdmin?: boolean | null };


export const CreateDeckDocument = gql`
    mutation CreateDeck($chosenCardTokenIds: [String!]!, $name: String!) {
  createDeck(chosenCardTokenIds: $chosenCardTokenIds, name: $name) {
    name
    id
  }
}
    `;
export type CreateDeckMutationFn = Apollo.MutationFunction<CreateDeckMutation, CreateDeckMutationVariables>;

/**
 * __useCreateDeckMutation__
 *
 * To run a mutation, you first call `useCreateDeckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeckMutation, { data, loading, error }] = useCreateDeckMutation({
 *   variables: {
 *      chosenCardTokenIds: // value for 'chosenCardTokenIds'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateDeckMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeckMutation, CreateDeckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeckMutation, CreateDeckMutationVariables>(CreateDeckDocument, options);
      }
export type CreateDeckMutationHookResult = ReturnType<typeof useCreateDeckMutation>;
export type CreateDeckMutationResult = Apollo.MutationResult<CreateDeckMutation>;
export type CreateDeckMutationOptions = Apollo.BaseMutationOptions<CreateDeckMutation, CreateDeckMutationVariables>;
export const GetDeckDocument = gql`
    query GetDeck($deckId: String!) {
  deck(id: $deckId) {
    chosenCardTokenIds
    name
  }
}
    `;

/**
 * __useGetDeckQuery__
 *
 * To run a query within a React component, call `useGetDeckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeckQuery({
 *   variables: {
 *      deckId: // value for 'deckId'
 *   },
 * });
 */
export function useGetDeckQuery(baseOptions: Apollo.QueryHookOptions<GetDeckQuery, GetDeckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeckQuery, GetDeckQueryVariables>(GetDeckDocument, options);
      }
export function useGetDeckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeckQuery, GetDeckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeckQuery, GetDeckQueryVariables>(GetDeckDocument, options);
        }
export type GetDeckQueryHookResult = ReturnType<typeof useGetDeckQuery>;
export type GetDeckLazyQueryHookResult = ReturnType<typeof useGetDeckLazyQuery>;
export type GetDeckQueryResult = Apollo.QueryResult<GetDeckQuery, GetDeckQueryVariables>;
export const GetDecksDocument = gql`
    query GetDecks {
  decks {
    chosenCardTokenIds
    name
    id
  }
}
    `;

/**
 * __useGetDecksQuery__
 *
 * To run a query within a React component, call `useGetDecksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDecksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDecksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDecksQuery(baseOptions?: Apollo.QueryHookOptions<GetDecksQuery, GetDecksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDecksQuery, GetDecksQueryVariables>(GetDecksDocument, options);
      }
export function useGetDecksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDecksQuery, GetDecksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDecksQuery, GetDecksQueryVariables>(GetDecksDocument, options);
        }
export type GetDecksQueryHookResult = ReturnType<typeof useGetDecksQuery>;
export type GetDecksLazyQueryHookResult = ReturnType<typeof useGetDecksLazyQuery>;
export type GetDecksQueryResult = Apollo.QueryResult<GetDecksQuery, GetDecksQueryVariables>;
export const UpdateDeckDocument = gql`
    mutation UpdateDeck($name: String!, $deckId: String!, $chosenCardTokenIds: [String!]!) {
  updateDeck(
    name: $name
    deckId: $deckId
    chosenCardTokenIds: $chosenCardTokenIds
  ) {
    id
  }
}
    `;
export type UpdateDeckMutationFn = Apollo.MutationFunction<UpdateDeckMutation, UpdateDeckMutationVariables>;

/**
 * __useUpdateDeckMutation__
 *
 * To run a mutation, you first call `useUpdateDeckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeckMutation, { data, loading, error }] = useUpdateDeckMutation({
 *   variables: {
 *      name: // value for 'name'
 *      deckId: // value for 'deckId'
 *      chosenCardTokenIds: // value for 'chosenCardTokenIds'
 *   },
 * });
 */
export function useUpdateDeckMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeckMutation, UpdateDeckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeckMutation, UpdateDeckMutationVariables>(UpdateDeckDocument, options);
      }
export type UpdateDeckMutationHookResult = ReturnType<typeof useUpdateDeckMutation>;
export type UpdateDeckMutationResult = Apollo.MutationResult<UpdateDeckMutation>;
export type UpdateDeckMutationOptions = Apollo.BaseMutationOptions<UpdateDeckMutation, UpdateDeckMutationVariables>;
export const DeleteDeckDocument = gql`
    mutation DeleteDeck($deckId: String!) {
  deleteDeck(deckId: $deckId) {
    id
  }
}
    `;
export type DeleteDeckMutationFn = Apollo.MutationFunction<DeleteDeckMutation, DeleteDeckMutationVariables>;

/**
 * __useDeleteDeckMutation__
 *
 * To run a mutation, you first call `useDeleteDeckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeckMutation, { data, loading, error }] = useDeleteDeckMutation({
 *   variables: {
 *      deckId: // value for 'deckId'
 *   },
 * });
 */
export function useDeleteDeckMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeckMutation, DeleteDeckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeckMutation, DeleteDeckMutationVariables>(DeleteDeckDocument, options);
      }
export type DeleteDeckMutationHookResult = ReturnType<typeof useDeleteDeckMutation>;
export type DeleteDeckMutationResult = Apollo.MutationResult<DeleteDeckMutation>;
export type DeleteDeckMutationOptions = Apollo.BaseMutationOptions<DeleteDeckMutation, DeleteDeckMutationVariables>;
export const CreateLobbyDocument = gql`
    mutation createLobby($name: String!, $numberOfWins: Int, $potencyCap: Int, $backgroundTokenId: Int, $useTimer: Boolean!, $tournamentId: String, $board: String) {
  createLobby(
    name: $name
    numberOfWins: $numberOfWins
    potencyCap: $potencyCap
    backgroundTokenId: $backgroundTokenId
    useTimer: $useTimer
    tournamentId: $tournamentId
    board: $board
  ) {
    id
    name
  }
}
    `;
export type CreateLobbyMutationFn = Apollo.MutationFunction<CreateLobbyMutation, CreateLobbyMutationVariables>;

/**
 * __useCreateLobbyMutation__
 *
 * To run a mutation, you first call `useCreateLobbyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLobbyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLobbyMutation, { data, loading, error }] = useCreateLobbyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      numberOfWins: // value for 'numberOfWins'
 *      potencyCap: // value for 'potencyCap'
 *      backgroundTokenId: // value for 'backgroundTokenId'
 *      useTimer: // value for 'useTimer'
 *      tournamentId: // value for 'tournamentId'
 *      board: // value for 'board'
 *   },
 * });
 */
export function useCreateLobbyMutation(baseOptions?: Apollo.MutationHookOptions<CreateLobbyMutation, CreateLobbyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLobbyMutation, CreateLobbyMutationVariables>(CreateLobbyDocument, options);
      }
export type CreateLobbyMutationHookResult = ReturnType<typeof useCreateLobbyMutation>;
export type CreateLobbyMutationResult = Apollo.MutationResult<CreateLobbyMutation>;
export type CreateLobbyMutationOptions = Apollo.BaseMutationOptions<CreateLobbyMutation, CreateLobbyMutationVariables>;
export const LobbiesDocument = gql`
    query lobbies {
  lobbies {
    id
    name
    userId
    isStarted
    firstMovePlayerId
    user {
      publicAddress
      id
      nonce
      ensName
    }
    settings {
      potencyCap
      numberOfWins
      backgroundTokenId
      useTimer
      board
    }
    activeUsers {
      id
      publicAddress
      nonce
      ensName
    }
    playedCards {
      userId
      tokenId
      lobbyId
      boardPositionCol
      boardPositionRow
      createdAt
      playedCardMetadata
    }
    players {
      id
      publicAddress
      ensName
    }
    matchScore
    gameNumber
    playerChosenDeck {
      id
      name
      chosenCardTokenIds
    }
    havePlayersSelectedCards
  }
}
    `;

/**
 * __useLobbiesQuery__
 *
 * To run a query within a React component, call `useLobbiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLobbiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLobbiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLobbiesQuery(baseOptions?: Apollo.QueryHookOptions<LobbiesQuery, LobbiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LobbiesQuery, LobbiesQueryVariables>(LobbiesDocument, options);
      }
export function useLobbiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LobbiesQuery, LobbiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LobbiesQuery, LobbiesQueryVariables>(LobbiesDocument, options);
        }
export type LobbiesQueryHookResult = ReturnType<typeof useLobbiesQuery>;
export type LobbiesLazyQueryHookResult = ReturnType<typeof useLobbiesLazyQuery>;
export type LobbiesQueryResult = Apollo.QueryResult<LobbiesQuery, LobbiesQueryVariables>;
export const LobbyDocument = gql`
    query lobby($name: String!) {
  lobby(name: $name) {
    id
    name
    isStarted
    activeUsers {
      id
      publicAddress
      ensName
    }
    playedCards {
      userId
      tokenId
      lobbyId
      boardPositionCol
      boardPositionRow
      createdAt
      playedCardMetadata
    }
    settings {
      potencyCap
      numberOfWins
      backgroundTokenId
      useTimer
      board
    }
    matchScore
    gameNumber
    firstMovePlayerId
    players {
      id
    }
    playerChosenCardsMetadata
    players {
      id
      publicAddress
      ensName
    }
    user {
      publicAddress
      id
      ensName
    }
    matchScore
    currentGame {
      id
    }
    playerChosenDeck {
      id
      name
      chosenCardTokenIds
    }
    havePlayersSelectedCards
  }
}
    `;

/**
 * __useLobbyQuery__
 *
 * To run a query within a React component, call `useLobbyQuery` and pass it any options that fit your needs.
 * When your component renders, `useLobbyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLobbyQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useLobbyQuery(baseOptions: Apollo.QueryHookOptions<LobbyQuery, LobbyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LobbyQuery, LobbyQueryVariables>(LobbyDocument, options);
      }
export function useLobbyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LobbyQuery, LobbyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LobbyQuery, LobbyQueryVariables>(LobbyDocument, options);
        }
export type LobbyQueryHookResult = ReturnType<typeof useLobbyQuery>;
export type LobbyLazyQueryHookResult = ReturnType<typeof useLobbyLazyQuery>;
export type LobbyQueryResult = Apollo.QueryResult<LobbyQuery, LobbyQueryVariables>;
export const JoinLobbyDocument = gql`
    mutation joinLobby($lobbyId: String!) {
  joinLobby(lobbyId: $lobbyId) {
    id
  }
}
    `;
export type JoinLobbyMutationFn = Apollo.MutationFunction<JoinLobbyMutation, JoinLobbyMutationVariables>;

/**
 * __useJoinLobbyMutation__
 *
 * To run a mutation, you first call `useJoinLobbyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinLobbyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinLobbyMutation, { data, loading, error }] = useJoinLobbyMutation({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *   },
 * });
 */
export function useJoinLobbyMutation(baseOptions?: Apollo.MutationHookOptions<JoinLobbyMutation, JoinLobbyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinLobbyMutation, JoinLobbyMutationVariables>(JoinLobbyDocument, options);
      }
export type JoinLobbyMutationHookResult = ReturnType<typeof useJoinLobbyMutation>;
export type JoinLobbyMutationResult = Apollo.MutationResult<JoinLobbyMutation>;
export type JoinLobbyMutationOptions = Apollo.BaseMutationOptions<JoinLobbyMutation, JoinLobbyMutationVariables>;
export const GameStartedDocument = gql`
    subscription gameStarted($lobbyId: String!) {
  startGame(lobbyId: $lobbyId) {
    id
    isStarted
  }
}
    `;

/**
 * __useGameStartedSubscription__
 *
 * To run a query within a React component, call `useGameStartedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGameStartedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameStartedSubscription({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *   },
 * });
 */
export function useGameStartedSubscription(baseOptions: Apollo.SubscriptionHookOptions<GameStartedSubscription, GameStartedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GameStartedSubscription, GameStartedSubscriptionVariables>(GameStartedDocument, options);
      }
export type GameStartedSubscriptionHookResult = ReturnType<typeof useGameStartedSubscription>;
export type GameStartedSubscriptionResult = Apollo.SubscriptionResult<GameStartedSubscription>;
export const StartGameDocument = gql`
    mutation startGame($lobbyId: String!) {
  startGame(lobbyId: $lobbyId) {
    id
  }
}
    `;
export type StartGameMutationFn = Apollo.MutationFunction<StartGameMutation, StartGameMutationVariables>;

/**
 * __useStartGameMutation__
 *
 * To run a mutation, you first call `useStartGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startGameMutation, { data, loading, error }] = useStartGameMutation({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *   },
 * });
 */
export function useStartGameMutation(baseOptions?: Apollo.MutationHookOptions<StartGameMutation, StartGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartGameMutation, StartGameMutationVariables>(StartGameDocument, options);
      }
export type StartGameMutationHookResult = ReturnType<typeof useStartGameMutation>;
export type StartGameMutationResult = Apollo.MutationResult<StartGameMutation>;
export type StartGameMutationOptions = Apollo.BaseMutationOptions<StartGameMutation, StartGameMutationVariables>;
export const PlayedCardDocument = gql`
    subscription playedCard($lobbyId: String!) {
  playedCard(lobbyId: $lobbyId) {
    userId
    tokenId
    lobbyId
    boardPositionCol
    boardPositionRow
    createdAt
    playedCardMetadata
  }
}
    `;

/**
 * __usePlayedCardSubscription__
 *
 * To run a query within a React component, call `usePlayedCardSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePlayedCardSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayedCardSubscription({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *   },
 * });
 */
export function usePlayedCardSubscription(baseOptions: Apollo.SubscriptionHookOptions<PlayedCardSubscription, PlayedCardSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PlayedCardSubscription, PlayedCardSubscriptionVariables>(PlayedCardDocument, options);
      }
export type PlayedCardSubscriptionHookResult = ReturnType<typeof usePlayedCardSubscription>;
export type PlayedCardSubscriptionResult = Apollo.SubscriptionResult<PlayedCardSubscription>;
export const PlayCardDocument = gql`
    mutation playCard($tokenId: String!, $lobbyId: String!, $boardPositionCol: Int!, $boardPositionRow: Int!) {
  playCard(
    tokenId: $tokenId
    lobbyId: $lobbyId
    boardPositionCol: $boardPositionCol
    boardPositionRow: $boardPositionRow
  ) {
    tokenId
  }
}
    `;
export type PlayCardMutationFn = Apollo.MutationFunction<PlayCardMutation, PlayCardMutationVariables>;

/**
 * __usePlayCardMutation__
 *
 * To run a mutation, you first call `usePlayCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlayCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playCardMutation, { data, loading, error }] = usePlayCardMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *      lobbyId: // value for 'lobbyId'
 *      boardPositionCol: // value for 'boardPositionCol'
 *      boardPositionRow: // value for 'boardPositionRow'
 *   },
 * });
 */
export function usePlayCardMutation(baseOptions?: Apollo.MutationHookOptions<PlayCardMutation, PlayCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlayCardMutation, PlayCardMutationVariables>(PlayCardDocument, options);
      }
export type PlayCardMutationHookResult = ReturnType<typeof usePlayCardMutation>;
export type PlayCardMutationResult = Apollo.MutationResult<PlayCardMutation>;
export type PlayCardMutationOptions = Apollo.BaseMutationOptions<PlayCardMutation, PlayCardMutationVariables>;
export const ChooseLobbyCardsAndDeckDocument = gql`
    mutation chooseLobbyCardsAndDeck($lobbyId: String!, $userId: Int!, $cardTokenIds: [String!]!, $deckId: String) {
  chooseLobbyCardsAndDeck(
    lobbyId: $lobbyId
    userId: $userId
    cardTokenIds: $cardTokenIds
    deckId: $deckId
  )
}
    `;
export type ChooseLobbyCardsAndDeckMutationFn = Apollo.MutationFunction<ChooseLobbyCardsAndDeckMutation, ChooseLobbyCardsAndDeckMutationVariables>;

/**
 * __useChooseLobbyCardsAndDeckMutation__
 *
 * To run a mutation, you first call `useChooseLobbyCardsAndDeckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseLobbyCardsAndDeckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseLobbyCardsAndDeckMutation, { data, loading, error }] = useChooseLobbyCardsAndDeckMutation({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *      userId: // value for 'userId'
 *      cardTokenIds: // value for 'cardTokenIds'
 *      deckId: // value for 'deckId'
 *   },
 * });
 */
export function useChooseLobbyCardsAndDeckMutation(baseOptions?: Apollo.MutationHookOptions<ChooseLobbyCardsAndDeckMutation, ChooseLobbyCardsAndDeckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChooseLobbyCardsAndDeckMutation, ChooseLobbyCardsAndDeckMutationVariables>(ChooseLobbyCardsAndDeckDocument, options);
      }
export type ChooseLobbyCardsAndDeckMutationHookResult = ReturnType<typeof useChooseLobbyCardsAndDeckMutation>;
export type ChooseLobbyCardsAndDeckMutationResult = Apollo.MutationResult<ChooseLobbyCardsAndDeckMutation>;
export type ChooseLobbyCardsAndDeckMutationOptions = Apollo.BaseMutationOptions<ChooseLobbyCardsAndDeckMutation, ChooseLobbyCardsAndDeckMutationVariables>;
export const CloseLobbyDocument = gql`
    mutation closeLobby($lobbyId: String!) {
  closeLobby(lobbyId: $lobbyId) {
    id
  }
}
    `;
export type CloseLobbyMutationFn = Apollo.MutationFunction<CloseLobbyMutation, CloseLobbyMutationVariables>;

/**
 * __useCloseLobbyMutation__
 *
 * To run a mutation, you first call `useCloseLobbyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseLobbyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeLobbyMutation, { data, loading, error }] = useCloseLobbyMutation({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *   },
 * });
 */
export function useCloseLobbyMutation(baseOptions?: Apollo.MutationHookOptions<CloseLobbyMutation, CloseLobbyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseLobbyMutation, CloseLobbyMutationVariables>(CloseLobbyDocument, options);
      }
export type CloseLobbyMutationHookResult = ReturnType<typeof useCloseLobbyMutation>;
export type CloseLobbyMutationResult = Apollo.MutationResult<CloseLobbyMutation>;
export type CloseLobbyMutationOptions = Apollo.BaseMutationOptions<CloseLobbyMutation, CloseLobbyMutationVariables>;
export const PlayerRecordsDocument = gql`
    query playerRecords {
  playerRecords {
    publicAddress
    totalWins
    totalLosses
  }
}
    `;

/**
 * __usePlayerRecordsQuery__
 *
 * To run a query within a React component, call `usePlayerRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerRecordsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlayerRecordsQuery(baseOptions?: Apollo.QueryHookOptions<PlayerRecordsQuery, PlayerRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerRecordsQuery, PlayerRecordsQueryVariables>(PlayerRecordsDocument, options);
      }
export function usePlayerRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerRecordsQuery, PlayerRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerRecordsQuery, PlayerRecordsQueryVariables>(PlayerRecordsDocument, options);
        }
export type PlayerRecordsQueryHookResult = ReturnType<typeof usePlayerRecordsQuery>;
export type PlayerRecordsLazyQueryHookResult = ReturnType<typeof usePlayerRecordsLazyQuery>;
export type PlayerRecordsQueryResult = Apollo.QueryResult<PlayerRecordsQuery, PlayerRecordsQueryVariables>;
export const PlayerChosenCardsDocument = gql`
    query PlayerChosenCards($lobbyId: String!, $userId: Int!) {
  playerChosenCards(lobbyId: $lobbyId, userId: $userId) {
    chosenCardTokenIds
  }
}
    `;

/**
 * __usePlayerChosenCardsQuery__
 *
 * To run a query within a React component, call `usePlayerChosenCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerChosenCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerChosenCardsQuery({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePlayerChosenCardsQuery(baseOptions: Apollo.QueryHookOptions<PlayerChosenCardsQuery, PlayerChosenCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerChosenCardsQuery, PlayerChosenCardsQueryVariables>(PlayerChosenCardsDocument, options);
      }
export function usePlayerChosenCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerChosenCardsQuery, PlayerChosenCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerChosenCardsQuery, PlayerChosenCardsQueryVariables>(PlayerChosenCardsDocument, options);
        }
export type PlayerChosenCardsQueryHookResult = ReturnType<typeof usePlayerChosenCardsQuery>;
export type PlayerChosenCardsLazyQueryHookResult = ReturnType<typeof usePlayerChosenCardsLazyQuery>;
export type PlayerChosenCardsQueryResult = Apollo.QueryResult<PlayerChosenCardsQuery, PlayerChosenCardsQueryVariables>;
export const SelectLobbyOpponentDocument = gql`
    mutation SelectLobbyOpponent($lobbyId: String!, $opponentId: Int!) {
  selectLobbyOpponent(lobbyId: $lobbyId, opponentId: $opponentId) {
    players {
      id
      publicAddress
    }
  }
}
    `;
export type SelectLobbyOpponentMutationFn = Apollo.MutationFunction<SelectLobbyOpponentMutation, SelectLobbyOpponentMutationVariables>;

/**
 * __useSelectLobbyOpponentMutation__
 *
 * To run a mutation, you first call `useSelectLobbyOpponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectLobbyOpponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectLobbyOpponentMutation, { data, loading, error }] = useSelectLobbyOpponentMutation({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *      opponentId: // value for 'opponentId'
 *   },
 * });
 */
export function useSelectLobbyOpponentMutation(baseOptions?: Apollo.MutationHookOptions<SelectLobbyOpponentMutation, SelectLobbyOpponentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectLobbyOpponentMutation, SelectLobbyOpponentMutationVariables>(SelectLobbyOpponentDocument, options);
      }
export type SelectLobbyOpponentMutationHookResult = ReturnType<typeof useSelectLobbyOpponentMutation>;
export type SelectLobbyOpponentMutationResult = Apollo.MutationResult<SelectLobbyOpponentMutation>;
export type SelectLobbyOpponentMutationOptions = Apollo.BaseMutationOptions<SelectLobbyOpponentMutation, SelectLobbyOpponentMutationVariables>;
export const ResetGameDocument = gql`
    mutation ResetGame($lobbyId: String!) {
  resetGame(lobbyId: $lobbyId) {
    id
  }
}
    `;
export type ResetGameMutationFn = Apollo.MutationFunction<ResetGameMutation, ResetGameMutationVariables>;

/**
 * __useResetGameMutation__
 *
 * To run a mutation, you first call `useResetGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetGameMutation, { data, loading, error }] = useResetGameMutation({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *   },
 * });
 */
export function useResetGameMutation(baseOptions?: Apollo.MutationHookOptions<ResetGameMutation, ResetGameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetGameMutation, ResetGameMutationVariables>(ResetGameDocument, options);
      }
export type ResetGameMutationHookResult = ReturnType<typeof useResetGameMutation>;
export type ResetGameMutationResult = Apollo.MutationResult<ResetGameMutation>;
export type ResetGameMutationOptions = Apollo.BaseMutationOptions<ResetGameMutation, ResetGameMutationVariables>;
export const CreateNextGameInMatchDocument = gql`
    mutation CreateNextGameInMatch($lobbyId: String!) {
  nextGameInMatch(lobbyId: $lobbyId) {
    gameNumber
    matchScore
    isStarted
  }
}
    `;
export type CreateNextGameInMatchMutationFn = Apollo.MutationFunction<CreateNextGameInMatchMutation, CreateNextGameInMatchMutationVariables>;

/**
 * __useCreateNextGameInMatchMutation__
 *
 * To run a mutation, you first call `useCreateNextGameInMatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNextGameInMatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNextGameInMatchMutation, { data, loading, error }] = useCreateNextGameInMatchMutation({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *   },
 * });
 */
export function useCreateNextGameInMatchMutation(baseOptions?: Apollo.MutationHookOptions<CreateNextGameInMatchMutation, CreateNextGameInMatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNextGameInMatchMutation, CreateNextGameInMatchMutationVariables>(CreateNextGameInMatchDocument, options);
      }
export type CreateNextGameInMatchMutationHookResult = ReturnType<typeof useCreateNextGameInMatchMutation>;
export type CreateNextGameInMatchMutationResult = Apollo.MutationResult<CreateNextGameInMatchMutation>;
export type CreateNextGameInMatchMutationOptions = Apollo.BaseMutationOptions<CreateNextGameInMatchMutation, CreateNextGameInMatchMutationVariables>;
export const JoinQuickmatchQueueDocument = gql`
    mutation JoinQuickmatchQueue {
  joinQuickmatchQueue {
    name
  }
}
    `;
export type JoinQuickmatchQueueMutationFn = Apollo.MutationFunction<JoinQuickmatchQueueMutation, JoinQuickmatchQueueMutationVariables>;

/**
 * __useJoinQuickmatchQueueMutation__
 *
 * To run a mutation, you first call `useJoinQuickmatchQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinQuickmatchQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinQuickmatchQueueMutation, { data, loading, error }] = useJoinQuickmatchQueueMutation({
 *   variables: {
 *   },
 * });
 */
export function useJoinQuickmatchQueueMutation(baseOptions?: Apollo.MutationHookOptions<JoinQuickmatchQueueMutation, JoinQuickmatchQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinQuickmatchQueueMutation, JoinQuickmatchQueueMutationVariables>(JoinQuickmatchQueueDocument, options);
      }
export type JoinQuickmatchQueueMutationHookResult = ReturnType<typeof useJoinQuickmatchQueueMutation>;
export type JoinQuickmatchQueueMutationResult = Apollo.MutationResult<JoinQuickmatchQueueMutation>;
export type JoinQuickmatchQueueMutationOptions = Apollo.BaseMutationOptions<JoinQuickmatchQueueMutation, JoinQuickmatchQueueMutationVariables>;
export const LeaveQuickmatchQueueDocument = gql`
    mutation LeaveQuickmatchQueue {
  leaveQuickmatchQueue
}
    `;
export type LeaveQuickmatchQueueMutationFn = Apollo.MutationFunction<LeaveQuickmatchQueueMutation, LeaveQuickmatchQueueMutationVariables>;

/**
 * __useLeaveQuickmatchQueueMutation__
 *
 * To run a mutation, you first call `useLeaveQuickmatchQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveQuickmatchQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveQuickmatchQueueMutation, { data, loading, error }] = useLeaveQuickmatchQueueMutation({
 *   variables: {
 *   },
 * });
 */
export function useLeaveQuickmatchQueueMutation(baseOptions?: Apollo.MutationHookOptions<LeaveQuickmatchQueueMutation, LeaveQuickmatchQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveQuickmatchQueueMutation, LeaveQuickmatchQueueMutationVariables>(LeaveQuickmatchQueueDocument, options);
      }
export type LeaveQuickmatchQueueMutationHookResult = ReturnType<typeof useLeaveQuickmatchQueueMutation>;
export type LeaveQuickmatchQueueMutationResult = Apollo.MutationResult<LeaveQuickmatchQueueMutation>;
export type LeaveQuickmatchQueueMutationOptions = Apollo.BaseMutationOptions<LeaveQuickmatchQueueMutation, LeaveQuickmatchQueueMutationVariables>;
export const GetLobbyTimerDocument = gql`
    query GetLobbyTimer($lobbyId: String!) {
  lobbyTimer(lobbyId: $lobbyId)
}
    `;

/**
 * __useGetLobbyTimerQuery__
 *
 * To run a query within a React component, call `useGetLobbyTimerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLobbyTimerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLobbyTimerQuery({
 *   variables: {
 *      lobbyId: // value for 'lobbyId'
 *   },
 * });
 */
export function useGetLobbyTimerQuery(baseOptions: Apollo.QueryHookOptions<GetLobbyTimerQuery, GetLobbyTimerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLobbyTimerQuery, GetLobbyTimerQueryVariables>(GetLobbyTimerDocument, options);
      }
export function useGetLobbyTimerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLobbyTimerQuery, GetLobbyTimerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLobbyTimerQuery, GetLobbyTimerQueryVariables>(GetLobbyTimerDocument, options);
        }
export type GetLobbyTimerQueryHookResult = ReturnType<typeof useGetLobbyTimerQuery>;
export type GetLobbyTimerLazyQueryHookResult = ReturnType<typeof useGetLobbyTimerLazyQuery>;
export type GetLobbyTimerQueryResult = Apollo.QueryResult<GetLobbyTimerQuery, GetLobbyTimerQueryVariables>;
export const CreateSwiss1v1TournamentDocument = gql`
    mutation CreateSwiss1v1Tournament($name: String!, $startTime: String!, $endTime: String!, $type: String!, $requiredWins: Int, $potency: Int, $timerRequired: Boolean, $board: String) {
  createSwiss1v1Tournament(
    name: $name
    startTime: $startTime
    endTime: $endTime
    type: $type
    requiredWins: $requiredWins
    potency: $potency
    timerRequired: $timerRequired
    board: $board
  ) {
    id
    name
    type
    startTime
    endTime
    tournamentSettings {
      id
      tournamentId
      requiredWins
      potency
      timerRequired
      board
    }
  }
}
    `;
export type CreateSwiss1v1TournamentMutationFn = Apollo.MutationFunction<CreateSwiss1v1TournamentMutation, CreateSwiss1v1TournamentMutationVariables>;

/**
 * __useCreateSwiss1v1TournamentMutation__
 *
 * To run a mutation, you first call `useCreateSwiss1v1TournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSwiss1v1TournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSwiss1v1TournamentMutation, { data, loading, error }] = useCreateSwiss1v1TournamentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      type: // value for 'type'
 *      requiredWins: // value for 'requiredWins'
 *      potency: // value for 'potency'
 *      timerRequired: // value for 'timerRequired'
 *      board: // value for 'board'
 *   },
 * });
 */
export function useCreateSwiss1v1TournamentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSwiss1v1TournamentMutation, CreateSwiss1v1TournamentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSwiss1v1TournamentMutation, CreateSwiss1v1TournamentMutationVariables>(CreateSwiss1v1TournamentDocument, options);
      }
export type CreateSwiss1v1TournamentMutationHookResult = ReturnType<typeof useCreateSwiss1v1TournamentMutation>;
export type CreateSwiss1v1TournamentMutationResult = Apollo.MutationResult<CreateSwiss1v1TournamentMutation>;
export type CreateSwiss1v1TournamentMutationOptions = Apollo.BaseMutationOptions<CreateSwiss1v1TournamentMutation, CreateSwiss1v1TournamentMutationVariables>;
export const GetAllTournamentsDocument = gql`
    query getAllTournaments($openForEntry: Boolean) {
  getAllTournaments(openForEntry: $openForEntry) {
    id
    name
    type
    startTime
    endTime
    tournamentSettings {
      tournamentId
      requiredWins
      potency
      timerRequired
      board
    }
  }
}
    `;

/**
 * __useGetAllTournamentsQuery__
 *
 * To run a query within a React component, call `useGetAllTournamentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTournamentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTournamentsQuery({
 *   variables: {
 *      openForEntry: // value for 'openForEntry'
 *   },
 * });
 */
export function useGetAllTournamentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTournamentsQuery, GetAllTournamentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTournamentsQuery, GetAllTournamentsQueryVariables>(GetAllTournamentsDocument, options);
      }
export function useGetAllTournamentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTournamentsQuery, GetAllTournamentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTournamentsQuery, GetAllTournamentsQueryVariables>(GetAllTournamentsDocument, options);
        }
export type GetAllTournamentsQueryHookResult = ReturnType<typeof useGetAllTournamentsQuery>;
export type GetAllTournamentsLazyQueryHookResult = ReturnType<typeof useGetAllTournamentsLazyQuery>;
export type GetAllTournamentsQueryResult = Apollo.QueryResult<GetAllTournamentsQuery, GetAllTournamentsQueryVariables>;
export const EnterTournamentDocument = gql`
    mutation EnterTournament($tournamentId: String!, $discordHandle: String) {
  enterTournament(tournamentId: $tournamentId, discordHandle: $discordHandle)
}
    `;
export type EnterTournamentMutationFn = Apollo.MutationFunction<EnterTournamentMutation, EnterTournamentMutationVariables>;

/**
 * __useEnterTournamentMutation__
 *
 * To run a mutation, you first call `useEnterTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterTournamentMutation, { data, loading, error }] = useEnterTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *      discordHandle: // value for 'discordHandle'
 *   },
 * });
 */
export function useEnterTournamentMutation(baseOptions?: Apollo.MutationHookOptions<EnterTournamentMutation, EnterTournamentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnterTournamentMutation, EnterTournamentMutationVariables>(EnterTournamentDocument, options);
      }
export type EnterTournamentMutationHookResult = ReturnType<typeof useEnterTournamentMutation>;
export type EnterTournamentMutationResult = Apollo.MutationResult<EnterTournamentMutation>;
export type EnterTournamentMutationOptions = Apollo.BaseMutationOptions<EnterTournamentMutation, EnterTournamentMutationVariables>;
export const StartTournamentDocument = gql`
    mutation StartTournament($tournamentId: String!) {
  startTournament(tournamentId: $tournamentId) {
    id
    name
    type
    startTime
    endTime
    tournamentSettings {
      id
      tournamentId
      requiredWins
      potency
      timerRequired
      board
    }
    tournamentMatch {
      tournamentMatchId
      tournamentEntrantMatchPairing {
        tournamentEntrant {
          user {
            publicAddress
          }
        }
      }
    }
  }
}
    `;
export type StartTournamentMutationFn = Apollo.MutationFunction<StartTournamentMutation, StartTournamentMutationVariables>;

/**
 * __useStartTournamentMutation__
 *
 * To run a mutation, you first call `useStartTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTournamentMutation, { data, loading, error }] = useStartTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useStartTournamentMutation(baseOptions?: Apollo.MutationHookOptions<StartTournamentMutation, StartTournamentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTournamentMutation, StartTournamentMutationVariables>(StartTournamentDocument, options);
      }
export type StartTournamentMutationHookResult = ReturnType<typeof useStartTournamentMutation>;
export type StartTournamentMutationResult = Apollo.MutationResult<StartTournamentMutation>;
export type StartTournamentMutationOptions = Apollo.BaseMutationOptions<StartTournamentMutation, StartTournamentMutationVariables>;
export const GetAllTournamentsCategorizedDocument = gql`
    query GetAllTournamentsCategorized {
  getAllTournamentsCategorized {
    openTournaments {
      id
      name
      type
      startTime
      endTime
      tournamentSettings {
        id
        tournamentId
        requiredWins
        potency
        timerRequired
        board
      }
    }
    closedTournaments {
      id
      name
      type
      startTime
      endTime
      tournamentSettings {
        id
        tournamentId
        requiredWins
        potency
        timerRequired
        board
      }
    }
    activeTournaments {
      id
      name
      type
      startTime
      endTime
      tournamentSettings {
        id
        tournamentId
        requiredWins
        potency
        timerRequired
        board
      }
      entrantsWithRecords {
        id
        matchesPlayed
        wins
        losses
        isCurrentlyPaired
        ensName
        publicAddress
        winner
      }
    }
  }
}
    `;

/**
 * __useGetAllTournamentsCategorizedQuery__
 *
 * To run a query within a React component, call `useGetAllTournamentsCategorizedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTournamentsCategorizedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTournamentsCategorizedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTournamentsCategorizedQuery(baseOptions?: Apollo.QueryHookOptions<GetAllTournamentsCategorizedQuery, GetAllTournamentsCategorizedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTournamentsCategorizedQuery, GetAllTournamentsCategorizedQueryVariables>(GetAllTournamentsCategorizedDocument, options);
      }
export function useGetAllTournamentsCategorizedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTournamentsCategorizedQuery, GetAllTournamentsCategorizedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTournamentsCategorizedQuery, GetAllTournamentsCategorizedQueryVariables>(GetAllTournamentsCategorizedDocument, options);
        }
export type GetAllTournamentsCategorizedQueryHookResult = ReturnType<typeof useGetAllTournamentsCategorizedQuery>;
export type GetAllTournamentsCategorizedLazyQueryHookResult = ReturnType<typeof useGetAllTournamentsCategorizedLazyQuery>;
export type GetAllTournamentsCategorizedQueryResult = Apollo.QueryResult<GetAllTournamentsCategorizedQuery, GetAllTournamentsCategorizedQueryVariables>;
export const GetTournamentDocument = gql`
    query GetTournament($name: String!) {
  getTournament(name: $name) {
    id
    name
    type
    startTime
    endTime
    tournamentSettings {
      potency
      requiredWins
      timerRequired
      board
    }
    tournamentMatch {
      tournamentMatchId
      pass
      winner
      loser
      winner_relation {
        user {
          publicAddress
          ensName
        }
      }
      loser_relation {
        user {
          publicAddress
          ensName
        }
      }
      tie
      tournamentEntrantMatchPairing {
        tournamentEntrant {
          user {
            publicAddress
            ensName
          }
        }
      }
      lobbyTournamentMatchJoin {
        lobby {
          name
        }
      }
    }
    entrantsWithRecords {
      id
      matchesPlayed
      wins
      losses
      isCurrentlyPaired
      ensName
      publicAddress
      winner
    }
  }
}
    `;

/**
 * __useGetTournamentQuery__
 *
 * To run a query within a React component, call `useGetTournamentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTournamentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTournamentQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetTournamentQuery(baseOptions: Apollo.QueryHookOptions<GetTournamentQuery, GetTournamentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTournamentQuery, GetTournamentQueryVariables>(GetTournamentDocument, options);
      }
export function useGetTournamentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTournamentQuery, GetTournamentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTournamentQuery, GetTournamentQueryVariables>(GetTournamentDocument, options);
        }
export type GetTournamentQueryHookResult = ReturnType<typeof useGetTournamentQuery>;
export type GetTournamentLazyQueryHookResult = ReturnType<typeof useGetTournamentLazyQuery>;
export type GetTournamentQueryResult = Apollo.QueryResult<GetTournamentQuery, GetTournamentQueryVariables>;
export const GetUserTournamentsForLobbyCreationDocument = gql`
    query GetUserTournamentsForLobbyCreation {
  getUserTournamentsForLobbyCreation {
    id
    name
    type
    startTime
    endTime
    tournamentSettings {
      tournamentId
      requiredWins
      potency
      timerRequired
      board
    }
    tournamentMatch {
      tournamentEntrantMatchPairing {
        tournamentEntrant {
          user {
            ensName
            publicAddress
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetUserTournamentsForLobbyCreationQuery__
 *
 * To run a query within a React component, call `useGetUserTournamentsForLobbyCreationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTournamentsForLobbyCreationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTournamentsForLobbyCreationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTournamentsForLobbyCreationQuery(baseOptions?: Apollo.QueryHookOptions<GetUserTournamentsForLobbyCreationQuery, GetUserTournamentsForLobbyCreationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTournamentsForLobbyCreationQuery, GetUserTournamentsForLobbyCreationQueryVariables>(GetUserTournamentsForLobbyCreationDocument, options);
      }
export function useGetUserTournamentsForLobbyCreationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTournamentsForLobbyCreationQuery, GetUserTournamentsForLobbyCreationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTournamentsForLobbyCreationQuery, GetUserTournamentsForLobbyCreationQueryVariables>(GetUserTournamentsForLobbyCreationDocument, options);
        }
export type GetUserTournamentsForLobbyCreationQueryHookResult = ReturnType<typeof useGetUserTournamentsForLobbyCreationQuery>;
export type GetUserTournamentsForLobbyCreationLazyQueryHookResult = ReturnType<typeof useGetUserTournamentsForLobbyCreationLazyQuery>;
export type GetUserTournamentsForLobbyCreationQueryResult = Apollo.QueryResult<GetUserTournamentsForLobbyCreationQuery, GetUserTournamentsForLobbyCreationQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    id
    publicAddress
    ensName
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocument = gql`
    query user($publicAddress: String!) {
  user(publicAddress: $publicAddress) {
    id
    publicAddress
    nonce
    joinedLobbyAt
    ensName
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const SignInUserDocument = gql`
    mutation SignInUser($signature: String!, $publicAddress: String!) {
  signInUser(signature: $signature, publicAddress: $publicAddress) {
    jwt
  }
}
    `;
export type SignInUserMutationFn = Apollo.MutationFunction<SignInUserMutation, SignInUserMutationVariables>;

/**
 * __useSignInUserMutation__
 *
 * To run a mutation, you first call `useSignInUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInUserMutation, { data, loading, error }] = useSignInUserMutation({
 *   variables: {
 *      signature: // value for 'signature'
 *      publicAddress: // value for 'publicAddress'
 *   },
 * });
 */
export function useSignInUserMutation(baseOptions?: Apollo.MutationHookOptions<SignInUserMutation, SignInUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInUserMutation, SignInUserMutationVariables>(SignInUserDocument, options);
      }
export type SignInUserMutationHookResult = ReturnType<typeof useSignInUserMutation>;
export type SignInUserMutationResult = Apollo.MutationResult<SignInUserMutation>;
export type SignInUserMutationOptions = Apollo.BaseMutationOptions<SignInUserMutation, SignInUserMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($publicAddress: String!) {
  createUser(publicAddress: $publicAddress) {
    nonce
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const QueryUserCardsDocument = gql`
    query queryUserCards($publicAddress: String!) {
  cards(publicAddress: $publicAddress)
}
    `;

/**
 * __useQueryUserCardsQuery__
 *
 * To run a query within a React component, call `useQueryUserCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUserCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUserCardsQuery({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *   },
 * });
 */
export function useQueryUserCardsQuery(baseOptions: Apollo.QueryHookOptions<QueryUserCardsQuery, QueryUserCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryUserCardsQuery, QueryUserCardsQueryVariables>(QueryUserCardsDocument, options);
      }
export function useQueryUserCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryUserCardsQuery, QueryUserCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryUserCardsQuery, QueryUserCardsQueryVariables>(QueryUserCardsDocument, options);
        }
export type QueryUserCardsQueryHookResult = ReturnType<typeof useQueryUserCardsQuery>;
export type QueryUserCardsLazyQueryHookResult = ReturnType<typeof useQueryUserCardsLazyQuery>;
export type QueryUserCardsQueryResult = Apollo.QueryResult<QueryUserCardsQuery, QueryUserCardsQueryVariables>;
export const QueryUserCardPacksDocument = gql`
    query queryUserCardPacks($publicAddress: String!) {
  cardPacks(publicAddress: $publicAddress)
}
    `;

/**
 * __useQueryUserCardPacksQuery__
 *
 * To run a query within a React component, call `useQueryUserCardPacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUserCardPacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUserCardPacksQuery({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *   },
 * });
 */
export function useQueryUserCardPacksQuery(baseOptions: Apollo.QueryHookOptions<QueryUserCardPacksQuery, QueryUserCardPacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryUserCardPacksQuery, QueryUserCardPacksQueryVariables>(QueryUserCardPacksDocument, options);
      }
export function useQueryUserCardPacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryUserCardPacksQuery, QueryUserCardPacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryUserCardPacksQuery, QueryUserCardPacksQueryVariables>(QueryUserCardPacksDocument, options);
        }
export type QueryUserCardPacksQueryHookResult = ReturnType<typeof useQueryUserCardPacksQuery>;
export type QueryUserCardPacksLazyQueryHookResult = ReturnType<typeof useQueryUserCardPacksLazyQuery>;
export type QueryUserCardPacksQueryResult = Apollo.QueryResult<QueryUserCardPacksQuery, QueryUserCardPacksQueryVariables>;
export const QueryUserBackgroundsDocument = gql`
    query queryUserBackgrounds($publicAddress: String!) {
  backgrounds(publicAddress: $publicAddress)
}
    `;

/**
 * __useQueryUserBackgroundsQuery__
 *
 * To run a query within a React component, call `useQueryUserBackgroundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryUserBackgroundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryUserBackgroundsQuery({
 *   variables: {
 *      publicAddress: // value for 'publicAddress'
 *   },
 * });
 */
export function useQueryUserBackgroundsQuery(baseOptions: Apollo.QueryHookOptions<QueryUserBackgroundsQuery, QueryUserBackgroundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryUserBackgroundsQuery, QueryUserBackgroundsQueryVariables>(QueryUserBackgroundsDocument, options);
      }
export function useQueryUserBackgroundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryUserBackgroundsQuery, QueryUserBackgroundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryUserBackgroundsQuery, QueryUserBackgroundsQueryVariables>(QueryUserBackgroundsDocument, options);
        }
export type QueryUserBackgroundsQueryHookResult = ReturnType<typeof useQueryUserBackgroundsQuery>;
export type QueryUserBackgroundsLazyQueryHookResult = ReturnType<typeof useQueryUserBackgroundsLazyQuery>;
export type QueryUserBackgroundsQueryResult = Apollo.QueryResult<QueryUserBackgroundsQuery, QueryUserBackgroundsQueryVariables>;
export const QueryBurnStatusDocument = gql`
    query queryBurnStatus($transactionHash: String!) {
  burnStatus(transactionHash: $transactionHash)
}
    `;

/**
 * __useQueryBurnStatusQuery__
 *
 * To run a query within a React component, call `useQueryBurnStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryBurnStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryBurnStatusQuery({
 *   variables: {
 *      transactionHash: // value for 'transactionHash'
 *   },
 * });
 */
export function useQueryBurnStatusQuery(baseOptions: Apollo.QueryHookOptions<QueryBurnStatusQuery, QueryBurnStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryBurnStatusQuery, QueryBurnStatusQueryVariables>(QueryBurnStatusDocument, options);
      }
export function useQueryBurnStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryBurnStatusQuery, QueryBurnStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryBurnStatusQuery, QueryBurnStatusQueryVariables>(QueryBurnStatusDocument, options);
        }
export type QueryBurnStatusQueryHookResult = ReturnType<typeof useQueryBurnStatusQuery>;
export type QueryBurnStatusLazyQueryHookResult = ReturnType<typeof useQueryBurnStatusLazyQuery>;
export type QueryBurnStatusQueryResult = Apollo.QueryResult<QueryBurnStatusQuery, QueryBurnStatusQueryVariables>;
export const IsUserAdminDocument = gql`
    query isUserAdmin {
  isUserAdmin
}
    `;

/**
 * __useIsUserAdminQuery__
 *
 * To run a query within a React component, call `useIsUserAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserAdminQuery(baseOptions?: Apollo.QueryHookOptions<IsUserAdminQuery, IsUserAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUserAdminQuery, IsUserAdminQueryVariables>(IsUserAdminDocument, options);
      }
export function useIsUserAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUserAdminQuery, IsUserAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUserAdminQuery, IsUserAdminQueryVariables>(IsUserAdminDocument, options);
        }
export type IsUserAdminQueryHookResult = ReturnType<typeof useIsUserAdminQuery>;
export type IsUserAdminLazyQueryHookResult = ReturnType<typeof useIsUserAdminLazyQuery>;
export type IsUserAdminQueryResult = Apollo.QueryResult<IsUserAdminQuery, IsUserAdminQueryVariables>;